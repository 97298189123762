
import { computed, defineComponent, inject, ref, watch } from 'vue'
import axios from '@/http'
import { modalInstance, notifyInstance } from '@/type'
import { useStore } from 'vuex'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

interface discont {
  key: string
  amount: number
  percentage: number
  voidedAmount: number
}

export default defineComponent({
  props: ['propRange'],
  setup(props) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const discountsMap = computed(() => globalVariable.value.discounts)

    const discounts = ref(new Array<discont>())
    const totalOriginPrice = ref(0)
    const exportType = ref('AnalysisDiscount')

    watch(
      () => props.propRange,
      async (range: any) => {
        discounts.value = []
        init()
      },
      {
        deep: true
      }
    )

    function init() {
      axios
        .get(`/saleReport/${storeId.value}/statistics/discount`, {
          params: {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          }
        })
        .then((res) => {
          const data = res.data
          discounts.value = data.discounts
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : Number(number).toLocaleString('en-US')
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content:
            '權限不足</br>' +
            '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    init()

    return {
      discounts,
      formatNumber,
      totalOriginPrice,
      discountsMap,
      exportExcelClick
    }
  }
})
