
import {
  watch,
  defineComponent,
  inject,
  ref,
  reactive,
  computed,
  onMounted
} from 'vue'
import axios from '@/http'
import { modalInstance, notifyInstance, sales } from '@/type'
import { useStore } from 'vuex'
import usePie from '@/plugins/usePie'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

interface performance {
  storeSale: number
  storeOnline: number
  storePre: number
  storePreOnline: number
  deposit: number
  quantity: number
  onlineQuantity: number
  preQuantity: number
  preOnlineQuantity: number
  unfinishPreOrderAmount: number
  storePartialPickup: number
  partialPickupQuantity: number
}

export default defineComponent({
  components: {},
  props: ['propRange'],
  setup(props) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const pieChart = ref(null)
    const chart = ref({
      setOption: function (data: any) {
        // do nothing.
      }
    })

    const performance = reactive({
      storeSale: 0,
      storeOnline: 0,
      storePre: 0,
      storePreOnline: 0,
      deposit: 0,
      quantity: 0,
      onlineQuantity: 0,
      preQuantity: 0,
      preOnlineQuantity: 0,
      unfinishPreOrderAmount: 0,
      storePartialPickup: 0,
      partialPickupQuantity: 0
    } as performance)

    const counts = ref(1)
    const exportType = ref('AnalysisPerformance')

    watch(
      () => props.propRange,
      async (range: any) => {
        Object.assign(performance, {} as performance)
        init()
      },
      {
        deep: true
      }
    )

    function init() {
      axios
        .get(`/saleReport/${storeId.value}/statistics/performance`, {
          params: {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          }
        })
        .then((res) => {
          Object.assign(performance, {
            storeSale: Number(res.data.storeSale),
            storeOnline: Number(res.data.storeOnline),
            storePre: Number(res.data.storePre),
            storePreOnline: Number(res.data.storePreOnline),
            deposit: Number(res.data.deposit),
            quantity: Number(res.data.quantity),
            onlineQuantity: Number(res.data.onlineQuantity),
            preQuantity: Number(res.data.preQuantity),
            preOnlineQuantity: Number(res.data.preOnlineQuantity),
            unfinishPreOrderAmount: Number(res.data.unfinishPreOrderAmount),
            storePartialPickup: Number(res.data.storePartialPickup),
            partialPickupQuantity: Number(res.data.partialPickupQuantity)
          })
          initChart()
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function formatNumber(number: any) {
      return number === undefined
        ? 0
        : Math.round(Number(number)).toLocaleString('en-US')
    }

    function getNumber(number: any) {
      if (typeof number !== 'number' || isNaN(number)) {
        return 0
      }
      return number
    }

    function initChart() {
      const total =
        performance.storeSale +
        performance.storePre +
        performance.storePartialPickup +
        performance.storeOnline +
        performance.storePreOnline

      const store = Math.round(
        ((performance.storeSale +
          performance.storePre +
          performance.storePartialPickup) /
          total) *
          100
      )
      const online = Math.round(
        ((performance.storeOnline + performance.storePreOnline) / total) * 100
      )

      chart.value.setOption({
        title: {
          left: 'center',
          top: 'center',
          text: [
            `{text|門市銷售} {font1|${
              total !== 0 && store >= 0 && online >= 0 ? store : 0
            }%}`,
            `{text|線上店取} {font2|${
              total !== 0 && store >= 0 && online >= 0 ? online : 0
            }%}`
          ].join('\n'),
          textStyle: {
            rich: {
              text: {
                width: 80,
                lineHeight: 60,
                color: '#616161',
                fontSize: 18,
                fontWeight: '600',
                align: 'left'
              },
              font1: {
                color: '#f4a732',
                fontWeight: 'bold',
                fontSize: 40
              },
              font2: {
                color: '#60b2f0',
                fontWeight: 'bold',
                fontSize: 40
              }
            }
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['70%', '100%'],
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 15
            },
            label: {
              show: false
            },
            data: [
              {
                value: store,
                name: '門市銷售',
                itemStyle: {
                  color: '#f4a732'
                }
              },
              {
                value: online,
                name: '線上店取',
                itemStyle: {
                  color: '#60b2f0'
                }
              }
            ]
          }
        ]
      })
    }

    onMounted(() => {
      const { resize } = usePie(pieChart.value)
      Object.assign(chart.value, usePie(pieChart.value))
      window.addEventListener('resize', () => {
        resize()
      })
    })

    init()

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content:
            '權限不足</br>' +
            '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    return {
      formatNumber,
      getNumber,
      performance,
      counts,
      pieChart,
      chart,
      exportExcelClick
    }
  }
})
