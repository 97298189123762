
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  watch,
  toRef
} from 'vue'
import {
  checkOutRecord,
  modalInstance,
  notifyInstance,
  orderRecord
} from '@/type'

import { useStore } from 'vuex'
import moment from 'moment'
import axios from '@/http'
import usePagination from '@/plugins/usePagination'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

export default defineComponent({
  props: ['propRange'],
  setup(props) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)
    const cardTypesMap = computed(() => globalVariable.value.cardTypes)

    const paymentGroups = ref(new Array<any>())
    const creditCardGroups = ref(new Array<any>())
    const exportStatisticsType = ref('AnalysisOnlineOrderStatistics')
    const exportType = ref('AnalysisOnlineOrder')

    watch(
      () => props.propRange,
      async (range: any) => {
        paymentGroups.value = []
        init()
      },
      {
        deep: true
      }
    )

    async function init() {
      for (const [key, value] of Object.entries(paymentsMap.value)) {
        const paginationData = reactive({
          page: 1,
          pageSize: 10,
          lastPage: 1,
          total: 0,
          orderRecords: ref(new Array<any>())
        })

        const paginationSetting = reactive({
          page: toRef(paginationData, 'page'),
          pageSize: toRef(paginationData, 'pageSize'),
          api: `/orders/online/${storeId.value}`,
          payload: {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm'),
            payment: key
          }
        })

        paymentGroups.value.push({
          payment: key,
          paginationSetting: paginationSetting,
          pagination: null,
          paginationData: paginationData,
          isOpen: false
        })
      }

      axios
        .get(`/orders/online/${storeId.value}/statistics`, {
          params: {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          }
        })
        .then((res) => {
          const data = res.data
          const payments = data.payments
          const creditCards = data.creditCards
          payments.forEach((payment: any) => {
            const index = paymentGroups.value.findIndex((paymentGroup: any) => {
              return paymentGroup.payment === payment.paymentId
            })
            if (index !== -1) {
              paymentGroups.value[index].quantity = payment.quantity
              paymentGroups.value[index].voidedQuantity = payment.voidedQuantity
            }
          })
          creditCards.forEach((creditCard: any) => {
            creditCardGroups.value[creditCard.creditCard] = creditCard
          })
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      axios
        .get(`/system/payments/${storeId.value}`)
        .then((res) => {
          const data = res.data
          for (const [key, value] of Object.entries(data.enablePayments)) {
            const index = paymentGroups.value.findIndex((paymentGroup: any) => {
              return paymentGroup.payment === key
            })

            if (index !== -1) {
              paymentGroups.value[index].isEnable = Boolean(value)
            }
          }
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始化失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : Number(number).toLocaleString('en-US')
    }

    function nextPage(pagination: any, paginationData: any) {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData(pagination, paginationData)
      })
    }

    function prePage(pagination: any, paginationData: any) {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData(pagination, paginationData)
      })
    }

    async function goPage(pagination: any, paginationData: any, page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData(pagination, paginationData)
      })
    }

    function getData(pagination: any, paginationData: any) {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.orderRecords = data.items
    }

    function GroupClick(group: checkOutRecord) {
      if (!group.isOpen) {
        if (group.pagination === null) {
          // 分頁未初始化
          usePagination(group.paginationSetting).then((res) => {
            const pagination = res
            const data = pagination?.getData()
            group.pagination = pagination
            group.paginationData.page = data.currentPage
            group.paginationData.lastPage = data.lastPage
            group.paginationData.pageSize = data.pageSize
            group.paginationData.total = data.total
            group.paginationData.orderRecords = data.items
          })
        }
      }

      group.isOpen = !group.isOpen
    }

    const totalQuantity = computed(() => {
      return paymentGroups.value.reduce((totalQuantity, paymentGroup: any) => {
        return totalQuantity + Number(paymentGroup.quantity ?? 0)
      }, 0)
    })

    const totalVoidedQuantity = computed(() => {
      return paymentGroups.value.reduce(
        (totalVoidedQuantity, paymentGroup: any) => {
          return totalVoidedQuantity + Number(paymentGroup.voidedQuantity ?? 0)
        },
        0
      )
    })

    const areaShow = ref([])

    const setRef = (el: never) => {
      areaShow.value.push(el)
    }

    function exportStatisticsExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportStatisticsType.value,
          storeId.value,
          {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    function exportExcelClick(type: any) {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            detailItemKey: type,
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    init()

    return {
      formatNumber,
      paymentsMap,
      nextPage,
      prePage,
      goPage,
      paymentGroups,
      cardTypesMap,
      totalQuantity,
      totalVoidedQuantity,
      creditCardGroups,
      GroupClick,
      setRef,
      areaShow,
      exportStatisticsExcelClick,
      exportExcelClick
    }
  }
})
