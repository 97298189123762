
import {
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  toRef,
  watch
} from 'vue'
import axios from '@/http'
import { checkOutRecord, modalInstance, notifyInstance } from '@/type'
import { useStore } from 'vuex'
import usePagination from '@/plugins/usePagination'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

export default defineComponent({
  props: ['propRange'],
  setup(props) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)

    const checkOutRecords = ref(new Array<checkOutRecord>())
    const totalTimes = ref(0)
    const exportType = ref('AnalysisRevenue')
    const totalAmount = computed(() => {
      return checkOutRecords.value.reduce(
        (totalAmount, checkOutRecord: checkOutRecord) => {
          return totalAmount + Number(checkOutRecord.totalAmount ?? 0)
        },
        0
      )
    })

    const totalOverShort = computed(() => {
      return checkOutRecords.value.reduce(
        (totalOverShort, checkOutRecord: checkOutRecord) => {
          return totalOverShort + Number(checkOutRecord.totalOverShort ?? 0)
        },
        0
      )
    })

    const totalVoidedAmount = computed(() => {
      return checkOutRecords.value.reduce(
        (totalVoidedAmount: number, checkOutRecord: checkOutRecord) => {
          return (
            totalVoidedAmount + Number(checkOutRecord.totalVoidedAmount ?? 0)
          )
        },
        0
      )
    })

    watch(
      () => props.propRange,
      async (range: any) => {
        checkOutRecords.value = []
        init()
      },
      {
        deep: true
      }
    )

    function GroupClick(group: checkOutRecord) {
      if (!group.isOpen) {
        if (group.pagination === null) {
          // 分頁未初始化
          usePagination(group.paginationSetting).then((res) => {
            const pagination = res
            const data = pagination?.getData()
            group.pagination = pagination
            group.paginationData.page = data.currentPage
            group.paginationData.lastPage = data.lastPage
            group.paginationData.pageSize = data.pageSize
            group.paginationData.total = data.total
            group.paginationData.handOverRecords = data.items
          })
        }
      }

      group.isOpen = !group.isOpen
    }

    async function init() {
      for (const [key, value] of Object.entries(paymentsMap.value)) {
        const paginationData = reactive({
          page: 1,
          pageSize: 10,
          lastPage: 1,
          total: 0,
          handOverRecords: ref(new Array<any>())
        })

        const paginationSetting = reactive({
          page: toRef(paginationData, 'page'),
          pageSize: toRef(paginationData, 'pageSize'),
          api: `/handovers/${storeId.value}/${key}`,
          payload: {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          }
        })

        checkOutRecords.value.push({
          payment: key,
          paginationSetting: paginationSetting,
          pagination: null,
          paginationData: paginationData,
          isOpen: false
        })
      }

      axios
        .get(`/handovers/${storeId.value}/statistics`, {
          params: {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          }
        })
        .then((res) => {
          const data = res.data
          totalTimes.value = data.totalTimes
          data.payments.forEach((item: any) => {
            const index = checkOutRecords.value.findIndex(
              (checkOutRecord: any) => {
                return checkOutRecord.payment === item.detailItemKey
              }
            )

            if (index !== -1) {
              checkOutRecords.value[index].totalAmount = item.totalAmount
              checkOutRecords.value[index].totalOverShort = item.totalOverShort
              checkOutRecords.value[index].totalVoidedAmount =
                item.totalVoidedAmount
            }
          })
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      axios
        .get(`/system/payments/${storeId.value}`)
        .then((res) => {
          const data = res.data
          for (const [key, value] of Object.entries(data.enablePayments)) {
            const index = checkOutRecords.value.findIndex(
              (checkOutRecord: any) => {
                return checkOutRecord.payment === key
              }
            )

            if (index !== -1) {
              checkOutRecords.value[index].isEnable = Boolean(value)
            }
          }
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始化失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : Number(number).toLocaleString('en-US')
    }

    function nextPage(pagination: any, paginationData: any) {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData(pagination, paginationData)
      })
    }

    function prePage(pagination: any, paginationData: any) {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData(pagination, paginationData)
      })
    }

    async function goPage(pagination: any, paginationData: any, page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData(pagination, paginationData)
      })
    }

    function getData(pagination: any, paginationData: any) {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.handOverRecords = data.items
    }

    function exportExcelClick(type: any) {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content:
            '權限不足</br>' +
            '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            detailItemKey: type,
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    init()

    return {
      formatNumber,
      paymentsMap,
      checkOutRecords,
      nextPage,
      prePage,
      goPage,
      GroupClick,
      totalAmount,
      totalOverShort,
      totalVoidedAmount,
      totalTimes,
      exportExcelClick
    }
  }
})
