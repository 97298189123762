import * as echarts from 'echarts/core'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer
])

const usePie = (element: any) => {
  const pieChart = echarts.init(element)

  // 封裝參數
  const setOption = (data: any) => {
    return pieChart.setOption(data)
  }

  // 封裝resize
  const resize = () => pieChart.resize()

  return { setOption, resize }
}

export default usePie
